import React from 'react';
import {render} from 'react-dom';
import App from './src/App';

render(<App/>, document.getElementById('root'));

if (module.hot) {
    module.hot.accept();
}

(() => {
    function fixVH() {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
    }

    fixVH();
    window.addEventListener("resize", () => fixVH());
})();
