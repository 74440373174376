import React, {useEffect, useState} from 'react';
import QrCode from './components/QrCode';
import registerDynamicSite from './utils/registerDynamicSite';
import logo from './assets/images/logo.svg';
import bgTopLeft from './assets/images/bgTopLeft.png';
import bgBottomRight from './assets/images/bgBottomRight.png';
import styles from './App.scss';

function App() {
    const [data, setData] = useState({
        title: '',
        description: '',
        dynamicUrl: '',
        logo: null,
        poweredByLogo: false,
    });
    const [error, setError] = useState(false);

    function onError(err) {
        console.log(err);
        setError(true);
    }

    function renderContent() {
        if (error) {
            return (
                <>
                    <div className={styles.title}>Sorry</div>
                    <div className={styles.description}>Your link is not valid</div>
                </>
            )
        }

        return (
            <>
                <div className={styles.title}>{data.title}</div>
                <div className={styles.description}>{data.description}</div>
                <QrCode url={data.dynamicUrl} logo={data.logo} width={250}/>
            </>
        );
    }

    useEffect(() => {
        registerDynamicSite(window.location.href, setData, onError);
    }, []);

    return (
        <div className={styles.app}>
            <img className={styles.topLeftImage} src={bgTopLeft}/>
            <img className={styles.bottomRightImage} src={bgBottomRight}/>
            {data.poweredByLogo && (
                <a href="https://teleportivity.com" target="_blank"><img className={styles.logo} src={logo}/></a>
            )}
            <div className={styles.content}>
                {renderContent()}
            </div>
        </div>
    );
}

export default App;
