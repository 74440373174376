import io from 'socket.io-client';
import generateId from './generateId';
import {SERVER_URL} from '../config';

function registerDynamicSite(url, onData, onError) {
    const socket = io(SERVER_URL, {
        transports: ['websocket'],
        query: {
            ssid: generateId(),
        }
    });

    function subscribe() {
        socket.emit('dynamic_qr_subscribe', {url}, (err, data) => {
            if (err) {
                onError && onError(err);
                return;
            }

            onData && onData(data);
        });

        socket.on('dynamic_qr_update', data => onData && onData(data));
    }

    socket.on('connect', subscribe);
}

export default registerDynamicSite;
