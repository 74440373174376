import React, {useEffect, useRef, useState} from 'react';
import QRCode from 'qrcode';
import generateId from '../utils/generateId';

function QrCode({url, logo, width}) {
    const [id, setId] = useState(generateId());
    const qrCanvasRef = useRef(null);

    const drawToCanvas = text => {
        QRCode.toCanvas(qrCanvasRef.current, text, {errorCorrectionLevel: 'H', width, margin: 3}, () => {
            if (!logo) {
                return;
            }
            const ctx = qrCanvasRef.current.getContext('2d');
            const image = new Image;
            image.crossOrigin = "anonymous";
            image.onload = () => {
                const imgWidth = logo ? width * 0.4 : width * 0.5;
                const imgHeight = image.height * imgWidth / image.width;
                ctx.drawImage(image, width / 2 - imgWidth / 2, width / 2 - imgHeight / 2, imgWidth, imgHeight);
            };
            image.onerror = () => setId(generateId());
            image.src = `${logo}?id=${id}`;
        });
    };

    useEffect(() => {
        if (url) {
            drawToCanvas(url);
        }
    }, [url, logo, id]);

    return <canvas ref={qrCanvasRef}/>;
}

export default QrCode;
